import React from "react";
import ReactPaginate from "react-paginate";
export const Pagination = ({ totalPages, pageNo, setData }) => {
  return (
    <>
      <nav className={"Page example"}>
        <ReactPaginate
          className="pagination pagination-primary  justify-content-center"
          previousLabel="<"
          breakLabel="..."
          nextLabel=">"
          previousClassName="page-item"
          nextClassName="page-item"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          nextLinkClassName="page-link"
          previousLinkClassName="page-link"
          activeClassName="active"
          marginPagesDisplayed={2}
          onPageChange={setData}
          pageCount={Math.ceil(totalPages)}
          renderOnZeroPageCount={null}
        />
      </nav>
    </>
  );
};
