import React, { useState } from 'react';
import './style.css'; // Import CSS file for styling

function MultiImageSelector() {
    const [selectedImages, setSelectedImages] = useState([]);
  
    const handleImageChange = (event) => {
      const files = event.target.files;
      const imagesArray = [];
  
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          imagesArray.push({ src: e.target.result, id: Date.now() + i });
          if (imagesArray.length === files.length) {
            setSelectedImages(prevImages => [...prevImages, ...imagesArray]);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    };
  
    const handleRemoveImage = (id) => {
      setSelectedImages(selectedImages.filter(image => image.id !== id));
    };
  
    return (
      <div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
        <div className='inputFileUpload'>
        <div className='uploadfile'>
            <h1>Upload File</h1>
            <p>(You can upload only JPEG and size should not exceed 30MB)</p>
            <div className='handleImage'>
            <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            multiple
            />
            </div>
        </div>
        </div>
</div>
</div>
        </div>

        <div className="image-container mt-3">
          {selectedImages.map((image) => (
            <div key={image.id} className="image-wrapper">
              <img src={image.src} alt={`image-${image.id}`} className="image" />

              <button onClick={() => handleRemoveImage(image.id)} className="remove-button">
                <img src='../Image/crossbutton.png' alt='' className='img-fluid' />
            </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
export default MultiImageSelector;
