
import React, { useState } from 'react';
import Viewinboxmsg from './viewInboxmesg';

const Inboxaccordion = () => {

    const [isDivOpen, setIsDivOpen] = useState(false);
    const handleToggle = () => {
      setIsDivOpen(!isDivOpen);
    };
  
    const [audience, setAudience] = useState(false);
    const ToggleAudience = () => {
      setAudience(!audience);
    };
  
    const [shedule, setShedule] = useState(false);
    const ToggleShedule = () => {
      setShedule(!shedule);
    };

    return (
        <div>
        <div className='' onClick={handleToggle} style={{cursor:"pointer"}}>
        <div className='d-flex align-item-center justify-content-between'>
        <div className='d-flex align-item-center'>
            <div>
                <img src='../Image/Rectangle1.png' alt='' className='img-fluid' />
            </div>
                <div className='mx-3'>
                    <h1>Corey Dokidis</h1>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took...</p>
                </div>
        </div>
        <div className='d-flex'>
            <p>18 Dec 2023, 22:29 CST (11 days ago)</p>
            <h6 className='mx-2'>{isDivOpen ? '-' : '+'}</h6>
        </div>
           
          </div>
          </div>

          {isDivOpen && (
            <div>
                <hr />
              <Viewinboxmsg />
            </div>
          )}

  </div>
  
    )
};

export default Inboxaccordion;


