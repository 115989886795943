import { createSlice } from "@reduxjs/toolkit";

export const sliceProjectData = createSlice({
  name: "projectData",
  initialState: {
    data: null, // changed the initial state to null
  },
  reducers: {
    fetched: (state, action) => {
      // state.data = { ...state.data, [name]: value };
      state.data = action.payload.data;
    },
    logout: (state) => {
      state.data = null; // sets the data to null when the user logs out
    },
  },
});

export const { fetched, logout } = sliceProjectData.actions;

export const SetProjectData = (data) => (dispatch) => {
  if (!data) throw Error("missing Data parameter");
  dispatch(fetched({ data }));
};

export const ClearProjectData = () => (dispatch) => {
  dispatch(logout());
};

export default sliceProjectData.reducer;
