import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function CustomePagination({ handlePageChange, currentPage, totalRows,handlePerRowsChange,perPage,totalCount }) { 
  return (
    <>
      <div className="d-flex justify-content-between align-items-center customPagination">
        <div className="" style={{ display: "flex" }}>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage-1}
            onPageChange={handlePageChange}
            rowsPerPage={perPage}
            onRowsPerPageChange={handlePerRowsChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} of ${count} items` // Customized text
            }
            rowsPerPageOptions={[5, 10, 25, 50]} // Optional: Customize rows per page options
          />
        </div>
        <div
          className="customPagination"
          
        >
          <Stack spacing={2}>
            <Pagination count={totalRows} page={currentPage} onChange={handlePageChange} />
          </Stack>
        </div>
      </div>
      <small className="text-disabled">
        The Date & Timestamp are displayed according to the UTC{" "}
      </small>
    </>
  );
}
