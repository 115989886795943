/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import moment from "moment";
import { useDispatch } from "react-redux";
import { apiURl } from "../../../store/actions";
import { API, makeSearchString } from "../../../apiwrapper";
import { SetloaderData } from "../../../store/reducer";
import { BASECONFIG } from "../../../config";
import { toast } from "react-toastify";

import { BsThreeDotsVertical } from "react-icons/bs";
import AllDocumentList from "../../Modal/AllDocumentList";
import { Pagination } from "../../Pagination/Pagination";
import DocumentCard from "./DocumentCard";
import DocumentFillterning from "./DocumentFillterning";
import CustomePagination from "../../../hooks/CustomePagination";
function DocumentType() {
  const [openIndex, setOpenIndex] = useState(-1);
  const dispatch = useDispatch();
  const [viewDoc, setViewDoc] = useState(false);
  const [projects, setProjects] = useState([]);
  const [sortType, setSortType] = useState("");
  const [sortIndex, setSortIndex] = useState(-1);
  const [listingData, setListingData] = useState([]);
  const [data, setData] = useState({});
  const [selectedStartdate, setSelectedStartdate] = useState("");
  const [selectedEnddate, setSelectedEnddate] = useState("");
  const [totalDocCount, setTotalDocCount] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filterVal, setFilterVal] = useState({
    searchKey: "",
    startDate: "",
    endDate: "",
    pageNo: 1,
    perPage: 10,
    projectIds: "",
    requestType: "Document",
    status: "",
  });

  const handleFilterChange = (e, name) => {
    setFilterVal({
      ...filterVal,
      [name]: e.target.value,
      pageNo: filterVal.pageNo,
    });
  };

  const handleOndatechange = (e, name) => {
    setFilterVal({ ...filterVal, [name]: e, pageNo: filterVal.pageNo });
  };

  const handlePageChange = (page, value) => {
    setFilterVal({ ...filterVal, pageNo: value });
  };
  const handlePerRowsChange = async (e) => {
    setFilterVal({ ...filterVal, perPage: e.target.value });
  };

  const handlePerPagesChange = async (perPageLimit) => {
    setFilterVal({ ...filterVal, perPage: perPageLimit });
  };

  const fetchListingData = async () => {
    try {
      let url = `${apiURl.updateUser}/work/docs`;
      const searchString = makeSearchString(filterVal);
      url = searchString ? `${url}?${searchString}` : url;
      dispatch(SetloaderData(true));
      const data = await API({
        base_url: BASECONFIG.BASE_URL_USER,
        url: `${url}`,
        method: "GET",
        dispatch,
      });

      if (data?.status || data?.status === "true") {
        setData(data);
        setTotalDocCount(data?.data?.length);

        setListingData(data?.data);
        setTotalRows(
          Math.ceil(Number(data?.totalCount) / Number(data?.perPage))
        );

        setTotalCount(Number(data?.totalCount));
      } else {
        setData({});
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(SetloaderData(false));
    }
  };

  useEffect(() => {
    fetchListingData();
  }, [filterVal]);

  const sortListings = (sortTitle, sType) => {
    const sortFunctions = {
      Date: (a, b) => moment(a?.date) - moment(b?.date),
      Document_Type: (a, b) => a?.type?.localeCompare(b?.type),
    };
    let newListingArr = [...listingData];
    const sortFn = sortFunctions[sortTitle];
    if (sortFn) {
      if (sType === "asc") {
        newListingArr.sort(sortFn);
      } else if (sType === "des") {
        newListingArr.sort((a, b) => sortFn(b, a));
      }
    }
    setListingData(newListingArr);
  };

  return (
    <>
      {viewDoc ? (
        <AllDocumentList
          show={viewDoc}
          handleClose={() => {
            setViewDoc(false);
            setProjects([]);
          }}
          data={projects}
          callBackFunction={fetchListingData}
        />
      ) : (
        ""
      )}
      <DocumentCard documentType={"Document"} totalLength={totalDocCount} />
      <div className="row card justify-content-end">
        <DocumentFillterning
          documentFillter={"Document"}
          handleOnchange={handleFilterChange}
          value={filterVal}
          handleOndatechange={handleOndatechange}
        />

        <div className="card-content">
          <hr className="hr" />
          <div className="responsiveTable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="tableHeading">
                    Date&Time
                    {sortIndex === 1 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Date",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Date",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(1);
                          setSortType("asc");
                          sortListings("Date", "asc");
                        }}
                      />
                    )}{" "}
                  </th>

                  <th scope="col" className="tableHeading">
                    Document Type
                    {sortIndex === 2 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Document_Type",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Document_Type",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(2);
                          setSortType("asc");
                          sortListings("Category", "asc");
                        }}
                      />
                    )}{" "}
                  </th>

                  <th scope="col" className="tableHeading">
                    Actions
                    {/* {sortIndex === 7 ? (
                    sortType === "asc" ? (
                      <FaArrowUp
                        onClick={() => {
                          setSortType(sortType === "asc" ? "des" : "asc");
                          sortListings(
                            "Action_Type",
                            sortType === "asc" ? "des" : "asc"
                          );
                        }}
                        className="mx-1"
                        style={{ cursor: "pointer", fontSize: "10px" }}
                      />
                    ) : (
                      <FaArrowDown
                        onClick={() => {
                          setSortType(sortType === "asc" ? "des" : "asc");
                          sortListings(
                            "Action_Type",
                            sortType === "asc" ? "des" : "asc"
                          );
                        }}
                        className="mx-1"
                        style={{ cursor: "pointer", fontSize: "10px" }}
                      />
                    )
                  ) : (
                    <img
                      className="mx-1"
                      src={"../Image/shotBy.svg"}
                      alt=""
                      onClick={() => {
                        setSortIndex(7);
                        setSortType("asc");
                        sortListings("Action_Type", "asc");
                      }}
                    />
                  )}{" "} */}
                  </th>
                  <th scope="col" className="tableHeading">
                    Renewal Date
                    {sortIndex === 3 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Renewal_Date",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Renewal_Date",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(3);
                          setSortType("asc");
                          sortListings("Renewal_Date", "asc");
                        }}
                      />
                    )}{" "}
                  </th>
                  <th scope="col" className="tableHeading">
                    Status
                    {sortIndex === 4 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Status",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Status",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(4);
                          setSortType("asc");
                          sortListings("Status", "asc");
                        }}
                      />
                    )}{" "}
                  </th>
                  <th scope="col" className="tableHeading">
                    Reference
                    {sortIndex === 5 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Reference",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Reference",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(5);
                          setSortType("asc");
                          sortListings("Reference", "asc");
                        }}
                      />
                    )}{" "}
                  </th>
                  {/* <th scope="col" className="tableHeading">
                    Sent/Received
                    {sortIndex === 6 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Send_Received",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Send_Received",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(6);
                          setSortType("asc");
                          sortListings("Send_Received", "asc");
                        }}
                      />
                    )}{" "}
                  </th> */}
                  {/* <th scope="col" className="tableHeading">
                  Type
                  {sortIndex === 4 ? (
                    sortType === "asc" ? (
                      <FaArrowUp
                        onClick={() => {
                          setSortType(sortType === "asc" ? "des" : "asc");
                          sortListings(
                            "Type",
                            sortType === "asc" ? "des" : "asc"
                          );
                        }}
                        className="mx-1"
                        style={{ cursor: "pointer", fontSize: "10px" }}
                      />
                    ) : (
                      <FaArrowDown
                        onClick={() => {
                          setSortType(sortType === "asc" ? "des" : "asc");
                          sortListings(
                            "Type",
                            sortType === "asc" ? "des" : "asc"
                          );
                        }}
                        className="mx-1"
                        style={{ cursor: "pointer", fontSize: "10px" }}
                      />
                    )
                  ) : (
                    <img
                      className="mx-1"
                      src={"../Image/shotBy.svg"}
                      alt=""
                      onClick={() => {
                        setSortIndex(4);
                        setSortType("asc");
                        sortListings("Type", "asc");
                      }}
                    />
                  )}{" "}
                </th> */}
                  <th scope="col" className="tableHeading">
                    More Action
                    {/* {sortIndex === 6 ? (
                    sortType === "asc" ? (
                      <FaArrowUp
                        onClick={() => {
                          setSortType(sortType === "asc" ? "des" : "asc");
                          sortListings(
                            "Action",
                            sortType === "asc" ? "des" : "asc"
                          );
                        }}
                        className="mx-1"
                        style={{ cursor: "pointer", fontSize: "10px" }}
                      />
                    ) : (
                      <FaArrowDown
                        onClick={() => {
                          setSortType(sortType === "asc" ? "des" : "asc");
                          sortListings(
                            "Action",
                            sortType === "asc" ? "des" : "asc"
                          );
                        }}
                        className="mx-1"
                        style={{ cursor: "pointer", fontSize: "10px" }}
                      />
                    )
                  ) : (
                    <img
                      className="mx-1"
                      src={"../Image/shotBy.svg"}
                      alt=""
                      onClick={() => {
                        setSortIndex(6);
                        setSortType("asc");
                        sortListings("Action", "asc");
                      }}
                    />
                  )}{" "} */}
                  </th>
                </tr>
              </thead>

              <tbody>
                {listingData?.length > 0 ? (
                  listingData?.map((row, index) => {
                    return (
                      <tr className="borderLine">
                        <td valign="middle">
                          {row?.date
                            ? moment(row?.date).local().format("DD/MM/YYYY")
                            : ""}
                        </td>

                        <td valign="middle">
                          {row?.documentType ? row?.documentType : "---"}
                        </td>
                        <td valign="middle">
                          <span
                            className="text-primary "
                            onClick={() => {
                              let obj = { ...row };
                              if (typeof obj?.document === "string") {
                                obj.document = [obj?.document];
                              }
                              console.log(obj, "data>> obj", row);

                              setProjects(obj);
                              setViewDoc(true);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <a>Click here</a>{" "}
                            {/* {row?.document?.[0]
                              ?.split(".")
                              ?.pop()
                              ?.toLowerCase()} 
                            {row?.document?.length > 1 ? (
                              <span>+{row?.document?.length - 1} </span>
                            ) : (
                              ""
                            )}*/}
                          </span>
                        </td>
                        <td valign="middle">
                          {row?.renewalDate
                            ? moment(row?.renewalDate)
                                .local()
                                .format("DD/MM/YYYY")
                            : "---"}
                        </td>
                        <td valign="middle">
                          {row?.status ? row?.status : "---"}
                        </td>

                        <td valign="middle">
                          {row?.status ? row?.type : "---"}
                        </td>

                        {/* <td valign="middle">{row?.type ? row?.type : "---"}</td> */}
                        <td valign="middle">
                          <>
                            <div
                              className="div-wrapper customeActionButton"
                              onClick={() =>
                                setOpenIndex(openIndex === index ? -1 : index)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img src="../Image/group1171276003.png" alt="" className="img-fluid" />
                              {/* <BsThreeDotsVertical size={25}  className="actionDot" /> */}

                              <div className="faEllipsisVertical customeEllipsisVertical">
                                <div
                                  className="divOpenClose"
                                  onClick={() =>
                                    setOpenIndex(
                                      openIndex === index ? -1 : index
                                    )
                                  }
                                >
                                  {openIndex === index && (
                                    <>
                                      <Link
                                        to={`#`}
                                        onClick={() => {
                                          let obj = { ...row };
                                          if (
                                            typeof obj?.document === "string"
                                          ) {
                                            obj.document = [obj?.document];
                                          }
                                          console.log(obj, "data>> obj", row);

                                          setProjects(obj);
                                          setViewDoc(true);
                                        }}
                                      >
                                        View Document
                                      </Link>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colspan="8" className="text-center text-danger">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CustomePagination
        handlePageChange={handlePageChange}
        currentPage={filterVal.pageNo}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        perPage={filterVal.perPage}
        totalCount={totalCount}
      />
    </>
  );
}

export default DocumentType;
