import { createSlice } from "@reduxjs/toolkit";

export const investorDashboardSlicing = createSlice({
  name: "investordashboardlisting",
  initialState: {
    overviewdata: [],
    myDocuments: [],
    totalInvestment: [],
    myWallet: [],
    myInvestment: [],
    myDocumentsDetails: [],
    walletSummary: [],
    linkedBankAccounts: [],
    investmentDetails: [],
  },
  reducers: {
    overviewfetched: (state, { payload }) => {
      state.overviewdata = payload.data;
    },
    mydocumentsfetched: (state, { payload }) => {
      state.myDocuments = payload.data;
    },
    totalInvestmentfetched: (state, { payload }) => {
      state.totalInvestment = payload.data;
    },
    myWalletfetched: (state, { payload }) => {
      state.myWallet = payload.data;
    },
    myInvestmentfetched: (state, { payload }) => {
      state.myInvestment = payload.data;
    },
    myDocumentsDetailsfetched: (state, { payload }) => {
      state.myDocumentsDetails = payload.data;
    },
    myWalletSummaryfetched: (state, { payload }) => {
      state.walletSummary = payload.data;
    },
    linkedBankAccountsfetched: (state, { payload }) => {
      state.linkedBankAccounts = payload.data;
    },
    investmentDetailsfetched: (state, { payload }) => {
      state.investmentDetails = payload.data;
    },
  },
});

const {
  overviewfetched,
  mydocumentsfetched,
  totalInvestmentfetched,
  myWalletfetched,
  myInvestmentfetched,
  myDocumentsDetailsfetched,
  myWalletSummaryfetched,
  linkedBankAccountsfetched,
  investmentDetailsfetched,
} = investorDashboardSlicing.actions;

export const SetOverviewListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(overviewfetched({ data }));
};

export const SetMyDocumentsListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(mydocumentsfetched({ data }));
};

export const SetTotalInvestmentListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(totalInvestmentfetched({ data }));
};

export const SetMyWalletListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(myWalletfetched({ data }));
};
export const SetMyInvestmentListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(myInvestmentfetched({ data }));
};
export const SetMyDocumentsDetailsListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(myDocumentsDetailsfetched({ data }));
};

export const SetMyWalletSummaryListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(myWalletSummaryfetched({ data }));
};

export const SetMyLinkedAccountListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(linkedBankAccountsfetched({ data }));
};

export const SetMyInvestmentDetailsListing = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(investmentDetailsfetched({ data }));
};

export default investorDashboardSlicing.reducer;
