/* eslint-disable no-unused-vars */
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ImageComp from "./ImageComp";
import ShariaBoardChairPerson from "./ShariaBoardChairMan";
import ShariaBoardAdvisoary from "./AdvisaryBoard";
import ManagementBoardChairPerson from "./ManagementBoard";

const MabrookTeam = ({ id }) => {
  const [key, setKey] = useState("shariaBoard");
  const shariaBoard = [
    "../Image/seriaBordPerson1.png",
    "../Image/seriaBordPerson2.png",
    "../Image/seriaBordPerson3.png",
    "../Image/seriaBordPerson4.png",
    "../Image/seriaBordPerson5.png",
    "../Image/seriaBordPerson6.png",
  ];

  const advisoryBoard = [
    "../Image/seriaBordPerson7.png",
    "../Image/seriaBordPerson8.png",
    "../Image/seriaBordPerson9.png",
    "../Image/seriaBordPerson10.png",
    "../Image/seriaBordPerson11.png",
    "../Image/seriaBordPerson12.png",
  ];

  const management = [
    "../Image/seriaBordPerson13.png",
    "../Image/seriaBordPerson14.png",
    "../Image/seriaBordPerson15.png",
    "../Image/seriaBordPerson16.png",
    "../Image/seriaBordPerson17.png",
    "../Image/seriaBordPerson18.png",
  ];

  const content = ["This is text"];

  return (
    <section className="mabrookSection">
      <div className="container">
        <div className="row">
          <div className="col-md-12" id="team1">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              style={{ display: "none" }}
            >
              <Tab eventKey="shariaBoard" title="">
                <h4 className="meetSharia mt-5">Meet Our Shariah Board </h4>
                <p className="mabrookPara mt-4">
                  Our esteemed Shariah Advisory board members closely monitor
                  Al-Mabrook's Islamic operations to ensure strict compliant with
                  Shariah guidelines. As recognized Islamic Finance scholars,
                  with decades of experience, our Shariah Board Members have
                  contributed significantly to the Islamic Banking and Finance
                  industry worldwide.
                </p>
              </Tab>
              <Tab eventKey="advisoryBoard" title="">
                <h4 className="meetSharia mt-5">Meet Our Advisory Board </h4>
                <p className="mabrookPara mt-4">
                  We are privileged to have a distinguished Advisory Board
                  comprised of esteemed individuals from diverse backgrounds and
                  areas of expertise. Our Advisory Board plays a critical role
                  in guiding our strategic decisions, providing valuable
                  insights, and ensuring that we stay at the forefront of
                  innovation and excellence in our industry.
                </p>
              </Tab>
              <Tab eventKey="management" title="">
                <h4 className="meetSharia mt-5">Meet Our Management Team</h4>
                <p className="mabrookPara mt-4">
                  We are proud to introduce our dedicated and experienced
                  management team, whose collective expertise and leadership
                  drive our organization's success and growth. With a focus on
                  innovation, integrity, and customer satisfaction, we are
                  committed to delivering exceptional value to our stakeholders
                  and making a positive impact in the communities we serve
                </p>
              </Tab>
            </Tabs>
          </div>

          <div className="col-lg-5"></div>
        </div>
        <div className="row mt-5" id="team">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="shariaBoard" title="Shariah Board">
              <ShariaBoardChairPerson arr={shariaBoard} />
            </Tab>

            <Tab eventKey="advisoryBoard" title="Advisory Board">
              <ShariaBoardAdvisoary arr={advisoryBoard} />
            </Tab>

            <Tab eventKey="management" title="Management">
              {/* <ImageComp arr={management} /> */}
              <ManagementBoardChairPerson arr={management} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </section>
  );
};
export default MabrookTeam;
