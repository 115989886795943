/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
function AllProjectsList({ show, handleClose, data = [] }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ backdropFilter: "blur(5px)" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Projects </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-2">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Title</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((ele, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{ele?.title}</td>
                    <td>
                      <img
                        src={ele.cardImage}
                        width={25}
                        height={25}
                        className="rounded-circle"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AllProjectsList;
