import React from "react";
import { Modal } from "react-bootstrap";

function ViewDescriptionPopup({
  show,
  handleClose,
  callBackFunction = () => {},
  selectData = {},
}) {
  return (
    <>
      <Modal
        show={show}
        size="xs"
        onHide={handleClose}
        backdrop="static"
        centered
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Modal.Header closeButton style={{border:"0"}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <>
              {selectData?.projectIds?.length > 0 ? (
                <>
                  <div className=" col-12 my-2">
                    <h5>Project Name</h5>
                    <p
                      title={selectData?.projectIds
                        ?.map((ele) => ele?.title)
                        ?.join(",")}
                    >
                      <span className="" style={{ cursor: "pointer" }}>
                        {selectData?.projectIds
                          ?.map((ele) => ele?.title)
                          ?.join(",")}
                      </span>
                    </p>
                  </div>
                  <hr />
                </>
              ) : (
                ""
              )}

              <div className=" col-12 my-2">
                <h5>Description of work flow</h5>
                <p
                  dangerouslySetInnerHTML={{ __html: selectData?.description }}
                ></p>
              </div>
            </>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 text-end mt-2">
            <button
              className="btn btn-primary me-1 ml-2 mb-1 btn-sm"
              id="submit"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewDescriptionPopup;
