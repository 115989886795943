import { createSlice } from "@reduxjs/toolkit";

export const sliceAddNewProjectData = createSlice({
  name: "addNewProject",
  initialState: {
    data: null, // changed the initial state to null
  },
  reducers: {
    fetched: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

export const { fetched } = sliceAddNewProjectData.actions;

export const SetAddNewProjectData = (data) => (dispatch) => {
  // if (!data) throw Error("missing Data parameter");
  dispatch(fetched({ data }));
};

export default sliceAddNewProjectData.reducer;
