import { useState } from "react";

const Typemessage =()=>{

    const [text, setText] = useState('');
    const handleChange =(event)=>{
        const inputValue = event.target.value;
        if(inputValue.length <= 1000){
            setText(inputValue);
        }
    }
    return(
     <>
        <div className="p-2">
            <div className="mt-0 replyUserIcon">
                <textarea className="form-control replytextarea" cols="5" rows="5" placeholder="Write Description" style={{color:"#001a5c", fontWeight:"500"}} maxLength={1000} onChange={handleChange}/>
            </div>
            <div style={{color:"#001a5c", fontWeight:"600"}} className="characterCount">
            {1000 - text.length } / 1000
            </div>
        </div>
        </>
    )
}

export default Typemessage;