import React from "react";
import Inboxaccordion from "./inboxAccordian";
import Viewinboxmsg from "./viewInboxmesg";

const InboxExpand =()=>{

    const items = [
        { title: 'Item 1', content: 'Content for item 1' },
        { title: 'Item 2', content: 'Content for item 2' },
        { title: 'Item 3', content: 'Content for item 3' },
      ];

    return(
       <>
       <div className="myDashBoard detailTabDashboard">
        <div className="container">
          <div className="card" style={{ border: "none" }}>
            <div className="card-body">
              <div className="overMain">
                <div className="d-flex justify-content-between">
                  <div className="">
                  <h2 className="overTitlemsg"><img src="../Image/backbutton.png" alt="" className="img-fluid" style={{cursor:"pointer"}}/> Advance calculation</h2> 
                  </div>
                  <div className="d-flex align-item-center">

                  <div className="me-1">
                    <div className="messageCountr">
                    <span className="mx-2">51-100 of 293</span>
                    <span style={{fontSize:"20px", cursor:"pointer"}}>&#x003C; &#x003E;</span>
                    </div> 
                  </div>
                    <img src="../Image/ImageDropDown.png" className="img-fluid mx-1" alt="" />
                    <img src="../Image/backImage.png" className="img-fluid mx-1" alt="" />
                    <img src="../Image/printIocn.png" className="img-fluid mx-1" alt="" />
                    <img src="../Image/DeleteIcon.png" className="img-fluid mx-1" alt="" />
                  </div>
                  </div>
                  <div className="mt-3">
                  <Inboxaccordion  />
                  </div>
                  {/* <Viewinboxmsg /> */}
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
       </>
    )
}
export default InboxExpand;