import moment from "moment";
import { memo, useState } from "react";
import { getPriceNumberFormate } from "../../Validation/InputValidation";
import { useNavigate } from 'react-router-dom';
import TockenomicsOverView from "./tockenomicsOverView";

/* eslint-disable jsx-a11y/alt-text */
const RightPanelPackage = ({ tokenData = {} }) => {
  const navigate = useNavigate();
  const [showTokenView,setShowTokenView] = useState(false)

  // const handleMoreInfoClick = (event) => {
  //   console.log(event)
  //   // event.defaultPrevented()
  //   setShowTokenView(true)
  // };
  return (
    <>
    {/* {showTokenView && 
     <TockenomicsOverView
     tokenomicsData={{}}
     tokenData={tokenData}
     showTokenView={showTokenView}
   />
    } */}
    
      <div className="token">
        <figure className="coin">
          {/* <img className="img-fluid" src="../Image/dollarImage.png" /> */}
          <img
            className="img-fluid rounded-circle"
            src={
              tokenData?.tokenImage
                ? tokenData?.tokenImage
                : "../../Image/dollarImage.png"
            }
            alt=""
          />
        </figure>
        <h3 className="my-4">
          {/* <b>{tokenData?.token_name}</b>{" "}
          {tokenData?.tokenType ? `(${tokenData?.tokenType})` : "Token"} */}
          MBR Digital Shares
        </h3>
        <p className="blueTitl">Number of shares in the collection:</p>
        {/* <p className="blueTitl">Number of token in the collection:</p> */ }
        {/* <p>{getPriceNumberFormate(tokenData?.circulating_supply)} SWT</p> */}
        <p>6,300</p>
        <hr className="my-3" />

        <p className="blueTitl">Locking Period:</p>
        {/* <p className="blueTitl">Buyback Tokens:</p> */}
        {/* <p>from {moment(tokenData?.launchAt).local().format("YYYY")}</p> */}
        <p>15 Months</p>

        <hr className="my-3" />

        {/* <p className="blueTitl">Premium account price:</p>
        <p>${getPriceNumberFormate(tokenData?.token_price)} USDT</p>
        <hr className="my-3" /> */}

        {/* <p className="blueTitl">Basic account price:</p> */}
        <p className="blueTitl">Projected ROI:</p>
        {/* <p>${getPriceNumberFormate(tokenData?.token_price)} USDT</p> */}
        <p>33%</p>
        <hr className="my-3" />

        {/* <p className="blueTitl">Burning SWT tokens for:</p> */}
        <p className="blueTitl">Price Per Share:</p>
        {/* <p>40% revenue per year</p> */}
        <p>$100 </p>

        <button className="btn moreInfo mb-2 mt-4" type="submit"   >More information</button>
      </div>

      {/*  <div className="token">
        <h3 className="my-4">Bronze Package</h3>
        <p className="mb-4">
          An investors package that adds additional value to the crowdfunding
          offer. Bronze allows you to increase your token purchase benefits.
        </p>

        <p className="blueTitl">INCLUDES:</p>
        <p>Electronic Investor Certificate</p>

        <p className="blueTitl">TIME OF IMPLEMENTATION:</p>
        <p>At the end of collection.</p>

        <p className="blueTitl">THE NUMBERS OF TOKENS</p>
        <p>At the end of collection.</p>

        <div className="mbr mt-4">
          <div className="mbrImg">
            <img src="../Image/dollarImage.png" /> MBR
          </div>
          <div>100-999</div>
        </div>

        <button className="btn moreInfo mb-2 mt-4">Buy package</button>
      </div>
 <div className="token">
        <h3 className="my-4">Bronze Package</h3>
        <p className="mb-4">
          An investors package that adds additional value to the crowdfunding
          offer. Bronze allows you to increase your token purchase benefits.
        </p>

        <p className="blueTitl">INCLUDES:</p>
        <p>Electronic Investor Certificate</p>

        <p className="blueTitl">TIME OF IMPLEMENTATION:</p>
        <p>At the end of collection.</p>

        <p className="blueTitl">THE NUMBERS OF TOKENS</p>
        <p>At the end of collection.</p>

        <div className="mbr mt-4">
          <div className="mbrImg">
            <img src="../Image/dollarImage.png" /> MBR
          </div>
          <div>100-999</div>
        </div>

        <button className="btn moreInfo mb-2 mt-4">Buy package</button>
      </div> */}
    </>
  );
};

export default memo(RightPanelPackage);
