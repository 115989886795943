/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { BASECONFIG } from "../config";
import { SetpopupReducerData } from "../store/reducer";

const api = async ({
  base_url,
  body,
  headers = {},
  method,
  signal,
  url,
  formData = false,
  dispatch,
}) => {
  headers["Access-Control-Allow-Origin"] = "*";
  headers.Authorization = `${localStorage.getItem("token") || ""}`;
  if (!formData) {
    headers["Content-Type"] = "application/json";
  }

  try {
    return await fetch(base_url + url, {
      method,
      headers,
      body: body ? (formData ? body : JSON.stringify(body)) : null,
      signal,
    })
      .then((response) => response.clone().json())
      .then((data) => {
        let { isSessionExist = 1, message = "" } = data;
        if (!isSessionExist || +isSessionExist !== 1) {
          setTimeout(() => {
            dispatch(
              SetpopupReducerData({
                modalType: "SessionNotExist",
                showModal: true,
                message: message,
              })
            );
          }, 1000);
        }
        return data;
      });
  } catch (error) {
    let { isSessionExist } = error;
    throw Error(error);
  }
};

// export const getAwsImageUrl = async (image, name = "awsImgUrl") => {
//   try {
//     if (typeof image === "string") {
//       return image;
//     }
//     let fd = new FormData();
//     fd.append("image", image);
//     let imgUrl = await allApi({
//       url: `${BASECONFIG.AWS_URL}/${name}`,
//       method: "POST",
//       formData: true,
//       body: fd,
//     }).then((data) => {
//       // return (
//       //   data?.data?.image.map((ele) => {
//       //     const [key, value] = Object.entries(ele)[0];
//       //     return { [key]: value?.Location };
//       //   }) || []
//       // );
//       return data?.data?.image?.Location;
//     });
//     return imgUrl;
//   } catch (error) {
//     throw Error(error);
//   }
// };

// export const allApi = async ({
//   body,
//   headers = {},
//   method,
//   signal,
//   url,
//   formData = false,
// }) => {
//   // headers['Access-Control-Allow-Origin'] = '*';

//   try {
//     return await fetch(url, {
//       method,
//       headers,
//       body: body ? (formData ? body : JSON.stringify(body)) : null,
//       signal,
//     })
//       .then((response) => response.clone().json())
//       .then((data) => data);
//   } catch (error) {
//     throw Error(error);
//   }
// };

export const getMultipleAwsImageUrl = async (image, name = "awsImgUrl") => {
  try {
    if (typeof image === "string") {
      return image;
    }
    let fd = new FormData();

    if (Object.values(image || {})?.length > 1) {
      Object.values(image || {})?.map((img) => {
        fd.append("image", img);
      });
    }

    // let fd = new FormData();
    // fd.append("image", image);
    let imgUrl = await allApi({
      url: `${BASECONFIG.AWS_URL}/${name}`,
      method: "POST",
      formData: true,
      body: fd,
    }).then((data) => {
      return (
        data?.data?.image?.map((ele) => {
          const [key, value] = Object.entries(ele)[0];
          return value?.Location;
        }) || []
      );
      // return data?.data?.image?.Location;
    });
    return imgUrl;
  } catch (error) {
    throw Error(error);
  }
};

export const makeSearchString = (filter) => {
  const searchParams = new URLSearchParams();
  for (const key in filter) {
    if (filter[key]) {
      searchParams.append(key, filter[key]);
    }
  }
  return searchParams.toString();
};
export const getAllAWSVideos = () => {
  let base_url = BASECONFIG.VIDEO_URL_AWS;
  let obj = {};
  obj.howToRaiseFundMP4 = `${base_url}/howToRaiseFund.mp4`;
  obj.howToRaiseFundWebm = `${base_url}/howToRaiseFund.webm`;
  obj.howToInvestFundMp4 = `${base_url}/howToInvestFund.mp4`;
  obj.howToInvestFundWebm = `${base_url}/howToInvestFund.webm`;
  obj.MabrookAnimationWhatisMabrookWebm = `${base_url}/MabrookAnimationWhatisMabrook.webm`;
  return obj;
};
export const allApi = async ({
  body,
  headers = {},
  method,
  signal,
  url,
  formData = false,
}) => {
  try {
    return await fetch(url, {
      method,
      headers,
      body: body ? (formData ? body : JSON.stringify(body)) : null,
      signal,
    })
      .then((response) => {
        if (response.url.includes("X-Amz-Credential")) {
          return true;
        }
        return response.clone().json();
      })
      .then((data) => {
        console.log({ data });
        return data;
      });
  } catch (error) {
    throw Error(error);
  }
};

export const getAwsImageUrl = async (image, folder = "awsImgUrl") => {
  try {
    if (typeof image === "string" || !(image instanceof Blob)) {
      return image;
    }
    const isVideo =
      image.type.startsWith("video") || /\.(?:mp4|m4v|webm|ogg)$/i.test(image);
    let fd = new FormData();
    fd.append("image", image);
    const data = await allApi({
      url: `${BASECONFIG.AWS_URL}/signedUrl`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let uploadUrl = isVideo
      ? data?.result?.video?.video_uploadUrl
      : data?.result?.image?.image_uploadUrl;
    let displayUrl = isVideo
      ? data?.result?.video?.video_Url
      : data?.result?.image?.image_url;
    await getFinalUrl(image, uploadUrl, folder);
    return displayUrl;
  } catch (error) {
    throw Error(error);
  }
};

const getFinalUrl = async (image, urls, folder) => {
  try {
    return await allApi({
      url: `${urls}`,
      method: "PUT",
      formData: true,
      body: image,
    }).then((data) => {
      console.log({ data });
      return true;
    });
    // return true;
  } catch (error) {
    throw Error(error);
  }
};

export const sendDocSignRequest = async ({
  pdfurl = "",
  contractName = "",
  userReminderTime = "",
  workFlowId = "",
}) => {
  try {
    let payload = { pdfurl, contractName, userReminderTime };
    let headers = {};
    headers["Access-Control-Allow-Origin"] = "*";
    headers.Authorization = `${localStorage.getItem("token") || ""}`;
    headers["Content-Type"] = "application/json";
    return await allApi({
      url: `${BASECONFIG?.BASE_URL_USER}/v1/common/dig-sign${
        workFlowId ? `/${workFlowId}` : ""
      }`,
      method: "POST",
      body: payload,
      headers,
    }).then((data) => {
      console.log(data, "dig-sign");
      return data;
    });
  } catch (error) {
    throw Error(error);
  }
};

export default api;
