import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function DocumentFillterning({documentFillter,handleOnchange,value,handleOndatechange}) {

  return (
    <div className="cardFillter">
    <div className="row align-items-center py-1" style={{rowGap:"5px"}}>
    <div className="col-md-3">
        <div className="form-group">
          <select
            className="form-select"
            onChange={(e) => {handleOnchange(e,'status')}}

            value={value?.status}
          >
            <option value={""} placeholder="">
              All
            </option>
           
            {documentFillter === 'Received' ? 
    <>
        <option value={"No+Action+Required"}>No Action Required</option>
        <option value={"Action+Pending"}>Action Pending</option>
        <option value={"Action+Done"}>Action Done</option> 
    </>
    :
    <>
        <option value={"Submitted"}>Submitted</option>
        <option value={"Pending"}>Pending</option>
    </>
}

           
         
            
          </select>
        </div>
      </div>
      <div className="col-md-3 mt-3-sm">
        <DatePicker
            yearDropdownItemNumber={110}
            selected={value.startDate}
            maxDate={new Date()}
            onChange={(e) => {handleOndatechange(e,'startDate')}}
            className="form-control"
            isClearable={value.startDate}
            placeholderText="Select start date"
            dateFormat="dd/MM/yyyy"
            minDate={new Date().setMonth(
              new Date().getMonth() - 18
            )}
        />
      </div>

      <div className="col-md-3">
        <DatePicker
          yearDropdownItemNumber={110}
          minDate={
            value.startDate ||
            new Date().setMonth(new Date().getMonth() - 18)
          }
          onChange={(e) => {handleOndatechange(e,'endDate')}}

          maxDate={new Date()}
          selected={value?.endDate}
          className="form-control"
          isClearable={value?.endDate}
          placeholderText="Select end date"
          dateFormat="dd/MM/yyyy"
        />
      </div>
      <div className="col-md-3">
        <div className="form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search by Document Type"
            name="searchKey"
            value={value?.searchKey}
            onChange={(e) => {handleOnchange(e,'searchKey')}}
          />
        </div>
      </div>
    
      <div className="col-md-3 ">
        <div className="d-flex">
          {/* <div>
            <div variant="primary" onClick={handleShow}>
              <FontAwesomeIcon icon={faDownload} />{" "}
              <span style={{ cursor: "pointer" }}>Grouped</span>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className="col-1">
        <div className="EmailImg">
          <FontAwesomeIcon
            icon={faEnvelope}
            onClick={() => fileUpload()}
          />
        </div>
      </div> */}

  
    </div>
  </div>
  )
}

export default DocumentFillterning