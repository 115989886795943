/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const ShariaBoardAdvisoary = () => {
  return (
    <>
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ChairPersonNameDesigNationMobileVersion">
                <div className="card">
                  <img
                    src="../Image/AbdulRaufSivany.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Abdul Rauf Sivany</h4>
                        <h6>Advisory Board Member</h6>
                      </div>
                      <div>
                        <a
                          href="https://linkedin.com/in/abdulrauf-sivany-4a452115/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/PeterWu.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Peter Wu</h4>
                        <h6>Advisory Board Member</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/peter-wu-233b3b3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/AnwarBelgaumi.png"
                    alt=""
                    className="card-img-top"
                  />

                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Anwar Belgaumi</h4>
                        <h6>Advisory Board Member</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/anwar-belgaumi-876a0766/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ShariaBoardAdvisoary;
