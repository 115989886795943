/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const ManagementBoardChairPerson = () => {
  return (
    <>
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ChairPersonNameDesigNationMobileVersion">
                <div className="card">
                  <img
                    src="../Image/FahadSiddiqui.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Fahad Siddiqui</h4>
                        <h6>Founder & CEO</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/fahad-siddiqui-aba1321a8/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/HarisMohammed.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Haris Mohammed Khan</h4>
                        <h6>Co-Founder & Director</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/haris-m-khan-325652a4/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/AtifAqeel.png"
                    alt=""
                    className="card-img-top"
                  />

                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Atif Aqeel</h4>
                        <h6>Co-Founder & Director</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/atif-aqeel-42a75714/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="ChairPersonNameDesigNationMobileVersion">
                <div className="card">
                  <img
                    src="../Image/TabindAftab.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Tabinda Aftab</h4>
                        <h6>Head of PMO</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/tabindaaftab/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/FahadHasan.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Fahad Hasan</h4>
                        <h6>Chief Compliance Officer</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/fahad-hasan-63424a9/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/PawanPandey.png"
                    alt=""
                    className="card-img-top"
                  />

                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Pawan Pandey</h4>
                        <h6>CTO</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/pawan-pandey-79354a1/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="ChairPersonNameDesigNationMobileVersion">
                <div className="card">
                  <img
                    src="../Image/HanifPate.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Hanif Patel</h4>
                        <h6>VP Risk Management</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/hanifpatel/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/TawfikAzrak.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Dr. Tawfik Azrak</h4>
                        <h6>Islamic Product Management</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/dr-tawfik-a-95920282/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/MarkoJankovic.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Marko Jankovic</h4>
                        <h6>Head of Human Centric Design</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/markojankovic/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="ChairPersonNameDesigNationMobileVersion">
                <div className="card">
                  <img
                    src="../Image/AsifKhan.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Asif Khan</h4>
                        <h6>Finance Manager</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/asifkhan-financialmodeling-budgeting-loans-payroll?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/ManishBanga.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Manish Banga</h4>
                        <h6>Tech Lead</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/bangamanish/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/KaziOmar.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Kazi Omar</h4>
                        <h6>Social Media Community Manager</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/kazimashiateomar/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="ChairPersonNameDesigNationMobileVersion">
                <div className="card">
                  <img
                    src="../Image/AhsanAbdullah.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Ahsan Abdullah</h4>
                        <h6>Social Media Manager</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/ahsanakhan/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/RasheeqaTahi.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Rasheeqa Tahir</h4>
                        <h6>Digital Marketing Strategist</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/rasheeqatahir/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/HaseebAhsan.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Haseeb Ahsan</h4>
                        <h6>Snr. Business Analyst</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/haseeb-ahsan3353/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="ChairPersonNameDesigNationMobileVersion">
                <div className="card">
                  <img
                    src="../Image/AzamKhan.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Azam Khan</h4>
                        <h6>Snr. QA Engineer</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/muhammad-azam-26b51023a/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/MuhammadJawad.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Muhammad Jawad</h4>
                        <h6>QA Engineer</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/jawad-sqa-automation-manual-engineer-test-2864851b4/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/HarisSaleem.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Haris Saleem</h4>
                        <h6>Snr. Mobile Experience Designer</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/harissaleem94/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ManagementBoardChairPerson;
