/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DocumentCard from "./DocumentCard";
import { Tab, Tabs } from "react-bootstrap";
import DocumentType from "./DocumentType";
import ReceivedDocuments from "./ReceivedDocuments";
import RequestedDocuments from "./RequestedDocuments";
import SendDocuments from "./SendDocuments";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetloaderData } from "../../../store/reducer";
import { apiURl } from "../../../store/actions";
import { API } from "../../../apiwrapper";
import { BASECONFIG } from "../../../config";
import { toast } from "react-toastify";

function NewDocumentManagement() {
  const dispatch = useDispatch();

  const [allProjects, setAllProjects] = useState([]);

  const fetchProjects = async () => {
    try {
      dispatch(SetloaderData(true));
      let url = `${apiURl.documentMasters}/projects`;
      await API({
        base_url: BASECONFIG.BASE_URL_USER,
        url: `${url}`,
        method: "GET",
        dispatch,
      }).then((data) => {
        if (data?.status || data?.status === "true") {
          setAllProjects(data?.getProjectData);
        } else {
          setAllProjects([]);
        }
      });
    } catch (error) {
      toast.error(error);
    } finally {
      dispatch(SetloaderData(false));
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);
  return (
    <>
      <section className="myDashBoard detailTabDashboard">
        <div className="container">
          <div className="row my-5" id="table-contextual">
            <div className="col-md-12">
              <div className="card" style={{ border: "none" }}>
                <div className="card-header">
                  <div className="row align-items-center py-1">
                    <div className="col-md-4">
                      <h4 className="card-title m-0">Document Management</h4>
                    </div>
                  </div>
                </div>
                <div className="card-content">
                 {/* <hr /> */}
                  <div className="row p-2 mt-3 ">
                    <Tabs
                      defaultActiveKey={1}
                      id="uncontrolled-tab-example"
                      className="mb-0 docomentTabResponsive"
                    >
                      <Tab eventKey={1} title="Received Documents">
                        <ReceivedDocuments  />
                      </Tab>
                      <Tab eventKey={2} title="Requested Documents">
                        <RequestedDocuments />
                      </Tab>
                      {/* <Tab eventKey={3} title="My Document">
                        <DocumentType />
                      </Tab> */}
                    
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


export default NewDocumentManagement