import { useState } from "react";

const Viewinboxmsg =()=>{
    const [isopen, setIsOpen] = useState(false);
    const replyButton =()=>{
        setIsOpen(!isopen);
    }

    const [text, setText] = useState('');

    const handleChange =(event)=>{
        const inputValue = event.target.value;
        if(inputValue.length <= 1000){
            setText(inputValue);
        }
    }
    return(
        <>
        <div className="my-4">
                        <div className="d-flex justify-content-between">
                        <div>
                            <p><span style={{color:"#5F718D", fontWeight:"500"}}>Subject:</span> <span style={{color:"#001A5C", fontWeight:"500"}}>Contrary to popular belief, Lorem Ipsum is not simply</span></p>
                        </div>
                        <div>
                            <p><span style={{color:"#5F718D", fontWeight:"500"}}>Wed, 22 Nov, 08:50 (1 day ago)</span></p>
                        </div>
                        </div>

                        <div className="d-flex justify-content-between my-1">
                        <div>
                            <p><span style={{color:"#5F718D", fontWeight:"500"}}>From:</span> <span style={{color:"#001A5C", fontWeight:"500"}}>Zain Saris</span></p>
                        </div>
                        <div>
                            <p><span style={{color:"#5F718D", fontWeight:"500"}}>Reference Number:</span> <span style={{color:"#001A5C", fontWeight:"500"}}>123kasfala</span></p>
                        </div>
                        </div>

                        <div className="mt-3">
                        <div>
                            <h3>Contrary to popular belief, Lorem Ipsum is not simply</h3>
                        </div>
                        <p className="my-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                        </div>

                    </div>

                    <hr />
                    <div className="my-4">
                        <div>
                            <button type="btn" className="forwordreply" onClick={replyButton}>
                                <img src="../Image/reply.png" alt="" className="img-fluid"/> Reply</button>
                            <button type="btn" className="forwordreply mx-3">
                                <img src="../Image/forword.png" alt="" className="img-fluid"/> Forword</button>
                        </div>
                        {isopen && (
                            <>
                                    <div className="p-2">
                                        <div className="mt-3 replyUserIcon">
                                            <img src="../Image/replyUserIcon.png" alt="" className="img-fluid mx-2" />
                                            <textarea className="form-control replytextarea" cols="5" rows="5" placeholder="Enter Message" style={{color:"#001a5c", fontWeight:"500"}} maxLength={1000} onChange={handleChange}/>
                                        </div>
                                        <div style={{color:"#001a5c", fontWeight:"600"}} className="characterCount">
                                        {1000 - text.length } / 1000
                                        </div>

                                        <div className="mt-3 text-end ">
                                            <button type="btn" className="btn btn-outline-danger mx-3 rounded-pill" style={{width:"10%"}}>Cancel</button>
                                            <button type="btn" className="btn btn-outline-primary rounded-pill" style={{width:"10%"}}>Send</button>
                                        </div>
                                    </div>
                            </>
                        )}
                    </div>


        </>
    )
}
export default Viewinboxmsg;