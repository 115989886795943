const ImageComp = ({ arr }) => {
  return (
    <div className="ImageWraperSection mt-5">
      <div className="row g-0">
        <div className="col-sm-6 col-lg-4">
        <div className="card">
            <img src={arr[0]} alt="" className="card-img-top" />
            <div className="card-body">
            <div className="ChairPersonNameDesigNation" style={{backgroundColor:"#fff"}}>
              <div className="ChairPersonName"> 
                <h4>Mufti Ismail Desai</h4>
                <h6>Chairman of Shariah Board</h6>
              </div>
              <div>
                <img src="../Image/mdi_linkedin.png" alt="" className="" />
              </div>
            </div>
            </div>
          </div>

          </div>

        <div className="col-sm-6 col-lg-4">

        <div className="card">
            <img src={arr[1]} alt="" className="card-img-top" />

            <div className="card-body">
            <div className="ChairPersonNameDesigNation" style={{backgroundColor:"#fff"}}>
              <div className="ChairPersonName"> 
                <h4>Dr. Mufti Yousuf Sultan</h4>
                <h6>Shariah Board Member</h6>
              </div>
              <div>
                <img src="../Image/mdi_linkedin.png" alt="" className="" />
              </div>
            </div>
            </div>
          </div>

          {/* <figure>
            <img src={arr[1]} alt="" className="img-fluid" />
        <div className="ChairPersonNameDesigNation my-2" style={{backgroundColor:"#fff"}}>
              <div className=""> 
                <h4>Ismail Desai</h4>
                <h6>Chairman of Shariah Board</h6>
              </div>
              <div>
                <img src="../Image/mdi_linkedin.png" alt="" className="" />
              </div>
            </div>
        </figure> */}

        </div>
        <div className="col-sm-6 col-lg-4">


        <div className="card">
            <img src={arr[2]} alt="" className="card-img-top" />
            <div className="card-body">
            <div className="ChairPersonNameDesigNation" style={{backgroundColor:"#fff"}}>
              <div className="ChairPersonName"> 
                <h4>Dr. Mufti Yousuf Sultan</h4>
                <h6>Shariah Board Member</h6>
              </div>
              <div>
                <img src="../Image/mdi_linkedin.png" alt="" className="" />
              </div>
            </div>
            </div>
          </div>


          {/* <figure>
            <img src={arr[2]} alt="" className="img-fluid" />
            <div className="ChairPersonNameDesigNation my-2" style={{backgroundColor:"#fff"}}>
              <div className=""> 
                <h4>Ismail Desai</h4>
                <h6>Chairman of Shariah Board</h6>
              </div>
              <div>
                <img src="../Image/mdi_linkedin.png" alt="" className="" />
              </div>
            </div>
        </figure> */}

        </div>
        <div className="col-sm-6 col-lg-4"><figure><img src={arr[3]} alt="" className="img-fluid" /></figure></div>
        <div className="col-sm-6 col-lg-4"><figure><img src={arr[4]} alt="" className="img-fluid" /></figure></div>
        <div className="col-sm-6 col-lg-4"><figure><img src={arr[5]} alt="" className="img-fluid" /></figure></div>
      </div>
    </div>
  );
};
export default ImageComp;
