/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const ShariaBoardChairPerson = () => {
  return (
    <>
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ChairPersonNameDesigNationMobileVersion">
                <div className="card">
                  <img
                    src="../Image/smailDesai.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Mufti Ismail Desai</h4>
                        <h6>Chairman of Shariah Board</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/muftiismaildesai/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img
                    src="../Image/MuftiYousufSultan.png"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <div
                      className="ChairPersonNameDesigNation"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="ChairPersonNames">
                        <h4>Dr. Mufti Yousuf Sultan</h4>
                        <h6>Shariah Board Member</h6>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/yousufsultan/"
                          target="_blank"
                        >
                          <img
                            src="../Image/mdi_linkedin.png"
                            alt=""
                            className=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="card">
                  <img
                    src="../Image/shariaCompliance.png"
                    alt=""
                    className="card-img-top"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ShariaBoardChairPerson;
