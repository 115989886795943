import { useEffect, useState } from "react";
import RightPanelPackage from "./rightPanelPackage";
import { getPriceNumberFormate } from "../../Validation/InputValidation";

const TockenomicsOverView = ({ tokenomicsData, tokenData = {} ,showTokenView}) => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    if (tokenomicsData) {
      // setdata(tokenomicsData); 
      setdata([]);
    }
  }, [tokenomicsData]);

  return (
    <>
      <section className="tabOverView">
        <div className="">
          <div className="row">
            {/* <div className="col-md-12">
              <h4 className="mileMstoneHeading pb-md-5">
                Token - {data.token_name}
              </h4>
            </div> */}
          </div>
          <div className="row">
            {data?.length > 0 ? 
           
             <div className="col-lg-9 fg">
              <div className="updateBox2">
                <div className="TockenPriceDetails">
                  <div className="TockenPrice">
                    <p className="nameTocken">Token price</p>
                    <p className="priceTocken">
                      ${getPriceNumberFormate(data.token_price)}
                    </p>
                  </div>
                  <hr className="mt-3" />
                </div>
                <div className="TockenPriceDetails mt-2">
                  <div className="TockenPrice">
                    <p className="nameTocken">Token name</p>
                    <p className="priceTocken">{data.token_name}</p>
                  </div>
                  <hr className="mt-3" />
                </div>
                <div className="TockenPriceDetails mt-2">
                  <div className="TockenPrice">
                    <p className="nameTocken">Token symbol</p>
                    {/* <img src={data.token_symbol} alt="" className="tockenImage" /> */}
                    {data.token_symbol}
                  </div>
                  <hr className="mt-3" />
                </div>
                <div className="TockenPriceDetails mt-2">
                  <div className="TockenPrice">
                    <p className="nameTocken">Total supply</p>
                    <p className="priceTocken">
                      ${getPriceNumberFormate(data.total_supply)}
                    </p>
                  </div>
                  <hr className="mt-3" />
                </div>
                <div className="TockenPriceDetails mt-2">
                  <div className="TockenPrice">
                    <p className="nameTocken">Max. supply</p>
                    <p className="priceTocken">
                      ${getPriceNumberFormate(data.max_supply)}
                    </p>
                  </div>
                  <hr className="mt-3" />
                </div>
                <div className="TockenPriceDetails mt-2">
                  <div className="TockenPrice">
                    <p className="nameTocken">Circulating supply</p>
                    <p className="priceTocken">
                      ${getPriceNumberFormate(data.circulating_supply)}
                    </p>
                  </div>
                  <hr className="mt-3" />
                </div>
              </div>
            </div> 
            : 
            <div className="col-lg-9 fg">
              <div className="updateBox2">
                <p>At the moment, we don't have any updates to share with you. However, we'll be sure to keep you in the loop and notify you via your registered email as soon as we have any new information to share</p>
              </div>
            </div>
            
            
          
          }
            {data?.length > 0 ? 
            <div className="col-lg-3 mt-5 mt-lg-0">
              <RightPanelPackage tokenData={tokenData} />
            </div> : "" }
          </div>
        </div>
      </section>
    </>
  );
};

export default TockenomicsOverView;
