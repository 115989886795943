import { useState } from "react";
import Typemessage from "./textarea";
import MultiImageSelector from "./multichooseImage";
import { Modal, Button } from "react-bootstrap";

const Composemessage =()=>{

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const [showModals, setShowModals] = useState(false);
    const handleShows = () => setShowModals(true);
    const handleCloses = () => setShowModals(false);
  
  
    return(
        <>
        
        <div className="myDashBoard detailTabDashboard">
        <div className="container">
          <div className="card" style={{ border: "none" }}>
            <div className="card-body">
              <div className="overMain">
                
                <div className="row">
                    <h1>Message</h1>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div>
                            <button type="btn" className="btn btn-outline-primary rounded-pill mx-3" style={{width:"10%"}}>Recived</button>
                            <button type="btn" className="btn btn-outline-primary rounded-pill" style={{width:"10%"}}>Draft</button>
                            <button type="btn" className="btn btn-outline-primary rounded-pill mx-3" style={{width:"10%"}}>Sent</button>
                            <button type="btn" className="btn btn-outline-primary rounded-pill" style={{width:"10%"}}>Store</button>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="my-3">
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Register</option>
                            <option value="1">Legality</option>
                            <option value="2">Advance calculation</option>
                            <option value="3">Loan</option>
                            <option value="3">Investment</option>
                        </select>
                            </div>

                        <div className="">
                            <input type="text" className="form-control" placeholder="Headline" />
                        </div>

                        <div className="my-3">
                            <Typemessage />
                        </div>

                        <div className="my-3">
                            <MultiImageSelector />
                        
                        </div>
                        <hr/>
                        <div className="text-end">
                        <button type="btn" className="btn btn-outline-danger rounded-pill mx-2" style={{width:"10%"}} onClick={handleShow}>Cancel</button>
                            <button type="btn" className="btn btn-outline-primary rounded-pill" style={{width:"10%"}} onClick={handleShows}>Send</button>
                        </div>
                            </div>
                            </div>

                  </div>
                  </div>
                  </div>
                  </div>
                  </div>


                  {/* SuspendModal */}
      <Modal show={showModal} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            {/* <div className="reactivatemodal">
              <img src="../Image/exclaimteryIcon.png" alt="" />
            </div> */}
            <div style={{textAlign:"center"}}>
            <h1 style={{fontSize:"23px", color:"#001A5C", fontWeight:"600"}}>Save as Draft</h1>
            <p style={{ textAlign: "center" }} className="my-3">
                If you exist without saving to draft, this message will be deleted permanently
            </p>
            </div>

            <div className="text-center mt-4">
              <button
                type="btn"
                onClick={handleClose}
                className="btn mx-1 btn-outline-secondary rounded-pill"
                style={{width:"50%"}}
              >
                No, Exist without saving
              </button>

              <button
                variant="primary"
                onClick={handleClose}
                className="btn mx-1 btn-outline-primary rounded-pill"
                style={{width:"40%"}}
              >
                Yes, Save Draft
              </button>
            </div>


            <div className="reactivatemodalButton mt-0 text-center">
            <button
                type="btn"
                onClick={handleClose}
                className="btn btn-outline-primary mx-3 rounded-pill"
                style={{width:"50%"}}
              >
                Continue
              </button>
            </div>

          </div>
        </Modal.Body>
      </Modal>



 {/* SuspendModal */}
 <Modal show={showModals} onHide={handleCloses} backdrop="static" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
             <div className="reactivatemodal">
              <img src="../Image/rightImage.png" alt="" />
            </div> 

            <div style={{textAlign:"center"}}>
            <p style={{ fontWeight:"600", fontSize:"22px", color:"rgb(0, 26, 92)" }} className="my-1">
                Message sent successfully     
            </p>
            </div>

            <div className="reactivatemodalButton mt-0 text-center">
            <button
                type="btn"
                onClick={handleCloses}
                className="btn btn-outline-primary mx-3 rounded-pill"
                style={{width:"50%"}}
              >
                Ok
              </button>
            </div>

          </div>
        </Modal.Body>
      </Modal>

        </>
    )
}
export default Composemessage;