import React from "react";
import { useSelector } from "react-redux";
import IsSessionExist from "./IsSessionExist";
import InternetConnectivityChecker from "./InternetConnectivityChecker";

function OpenModal() {
  const { popupReducer } = useSelector((state) => state);
  const { modalType = "", showModal = false } = popupReducer?.modal;

  return (
    <>
      {showModal ? (
        <>
          {modalType === "SessionNotExist" && <IsSessionExist />}
          {modalType === "INTERNETCHECKER" && <InternetConnectivityChecker />}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default OpenModal;
