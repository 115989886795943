import { createSlice } from "@reduxjs/toolkit";

export const sliceAllProjects = createSlice({
  name: "projectlisting",
  initialState: {
    data: [],
  },
  reducers: {
    fetched: (state, { payload }) => {
      state.data = payload.data;
    },
  },
});

const { fetched } = sliceAllProjects.actions;

export const SetProjectListing = (data) => async (dispatch) => {
  // if (!data) throw Error("missing data parameter");
  dispatch(fetched({ data }));
};

export default sliceAllProjects.reducer;
