export const validationMessages = {
  deleteConfirm: "Are you sure, you want to delete this item?",
  changeStatus: "Are you sure, you want to do this?",
  require: "This field is required",
  no_mandatory: "Agreeing to the declaration is a mandatory requirement",
  blogDescriptionMax: "Description is Too Long ( Allow Max 100 Characters )",
  unableToLogin: "Unable to login. Please try after some time.",
  image: `File is required`,
  name: `Name is required`,
  nameMax: `Name is too long ( Maximum 100 characters )`,
  NoFcopies: `Copies are required.`,
  categories: `Categories are required`,
  price: `Price is required`,
  royalty: `Royalty is required`,
  coverimage: `Cover image is required`,
  descriptionMax: "Description is too long ( Maximum 1000 characters )",
  descriptionLow: "Description is required",
  fName: `First name is required. `,
  lName: `Last name is required. `,
  allowAlphabets: `This field allow alphabets only.`,
  emailReq: `Email is required.`,
  validEmail: `Enter valid Email`,
  passwReq: `Password is required`,
  validPass: `Password must included at least`,
  cpasswReq: `Confirm Password is required`,
  currentasswReq: `Current Password is required`,
  notMatchPassw: `Password and Confirm Password should be same`,
  phoneReq: `Contact number is required`,
  validMobile: `Please enter a valid mobile number.`,
  reqEnquiryType: "Enquiry type is required.",
  reqQueryMessage: "Query message is required.",
  allowMax8: "Allow max 8 characters.",
  Time_from: "From time is required",
  royality: `Royalty should be less Than %s or min 1`,
  otpEnter: "Please enter OTP.",
  validOtp: `Please enter a valid OTP.`,
  invalTime: "Please enter a valid time.",
  sameTime: "To time should be greater than Form time.",
  reConnect: `Please disconnect other wallet`,
  greaterprice: `Price should be greater than or equal to 0.01`,
  selectModule: "Please select module",
  country: "Country is required",
  province: "Province is required",
  city: "City is required",
  streetNumber: "Street Number is required",
  streetName: "Street Name is required",
  postalCode: "Postal Code is required",
  dob: "Date of Birth is required",
  pep: "This field is required",
  married: "This field is required",
  personal_Depandants: "This field is required",
  occupation: "Occupation is required",
  employment_name: "Employment Name is required",
  employer_street_name: "Street name is required",
  employer_suite_number: "Suite Number is required",
  employer_country: "Country is required",
  employer_city: "City is required",
  employer_provinance: "State is required",
  postalcode: "Postal code is required.",
  total_Income: "Total Income is required",
  net_Worth: "Net Worth is required",
  net_Financial_Assests: "Net financial Assests is required",
  jurisdiction: "Jurisdiction is required",
  investment_Knowledge: "Investment Knowledge is required",
  risk_Tolerance: "Risk Tolerance is required",
  investment_Time_Horizon: "Investement Time Horizon is required",
  investment_Objectives: "Investment Objectives is required",
  total_Amount_Invested: "Total Amount is required",
  address1: "Address is required",
  high_Risk_Investment: "This field is required",
  investing_Entirely: "This field is required",
  equivesta: "This field is required",
  investment_Quickly: "This field is required",
  info_Investment: "This field is required",
  ontario_Securities_Commision: "This field is required",
  first_name: "This field is required",
  investorString: "This field is required",
  orgType: "This field is required",
  countryCode: "Country Code is required",
  greaterSoft: `Soft Cap should be less than or equal Hard Cap.`,
  lessHard: `Hard Cap should be greater than or equal Soft Cap.`,
  docSign: `Please sign the document!`,
};
const apiURl = {
  editCollection: "/v1/projects",
  collection: "/v1/projects",
  getCurrency: "/v1/currency/get",
  like: "/v1/projects/like",
  getWhitepaper: "/v1/whitepaper",
  getBlogs: "/v1/blogs/user/blogs",
  getFaq: "/v1/faqs",
  setTeam: "/v1/teams",
  getTeam: "/v1/teams/project",
  getHomeTeam: "/v1/teams",
  createQuestion: "/v1/qas",
  getQaMaster: "/v1/qas",
  getLink: "/v1/links",
  createLogin: "/v1/common/otherLogin",
  resetMail: "/v1/user/mail/reset",
  resetPassword: "/v1/user/password/reset",
  getLinkedInProfile: "/linkedinLogin",
  getToken: "/v1/common/linkedin/token",
  category: "/v1/categories",
  getCollection: "/v1/projects",
  addCategory: "/addCategory",
  updateUser: "/v1/user",
  addOverview: "/v1/overviews",
  getOverview: "/getOverview",
  getOverviewById: "/v1/overviews",
  editOverview: "/editOverview",
  deleteOverview: "/deleteOverview",
  verifyPoa: "/v1/common/verify",
  deleteOverviewImage: "/delImage",
  createUpdates: "/v1/updates",
  getUpdatesById: "/v1/updates",
  updatesId: "/editUpdates",
  createMilestone: "/v1/milestones",
  editMilestoneById: "/editMilestone",
  getMilestone: "/v1/milestones",
  createProjectdocument: "/v1/documents",
  editProjectdocument: "/editProjectdocument",
  getProjectdocument: "/v1/projects/document",
  shareRegister: "/v1/tokens/shareRegister",
  getFaqList: "/v1/faqs",
  getInvestors: "/v1/dashboard/investor/management",
  getInvestorDetail: "/v1/dashboard/investor",
  getCampaign: "/v1/campaign",
  activeProjects: "/v1/dashboard/active",
  projectList: "/v1/dashboard/projectList",
  projectData: "/v1/dashboard/investor/myDashboard",
  documents: "/v1/projects/document",
  documentPost: "/v1/documents",
  getAllFaq: "/v1/faqs/projectId",
  addFaq: "/v1/faqs",
  getTokenomics: "/v1/toknomics",
  getTotalInvestmentDetail: "/v1/dashboard/total",
  changePassword: "/v1/dashboard/setting/passwordchange",
  cancelAndRefund: "/v1/dashboard/issuer/cancelRefund",
  refundDetails: "/v1/dashboard/request/singel",
  faqLike: "/v1/faqs/like",
  tokenDetail: "/v1/tokens/issuer/list",
  auditlog: "/auditlog/v1",
  accounts: "/v1/accounts",
  changeAccounts: "/v1/user/account/change",
  preKyc: "/v1/companies",
  documentCategories: "/v1/documentCategories",
  documentMasters: "/v1/documentMasters",
  notificationCategory: "/v1/notificationCategory",
  accessToken: "/v1/user/access/token",
  getSignUrl: "/user/v1/documentMasters/document/sign",
  getSignStatus: "/v1/documentMasters/pull/status",
};

export const initialiseSocialMedia = [
  {
    name: "linkedin",
    link: "",
    image: "",
  },
  {
    name: "facebook",
    link: "",
    image: "",
  },
  {
    name: "twitter",
    link: "",
    image: "",
  },
  {
    name: "instagram",
    link: "",
    image: "",
  },
];

export default apiURl;
