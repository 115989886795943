import axios from "axios";
import { API } from "../apiwrapper";
import { BASECONFIG } from ".";
import { apiURl } from "../store/actions";
import { LogoutUser, SetAuthUserData } from "../store/reducer/authUser";
import { LogoutGoogleUser } from "../store/reducer/authGoogleUser";
import { SetKycData, SetPreKycData } from "../store/reducer/kycData";
import { SetAddNewProjectData } from "../store/reducer";

export const getCurrentLocation = async () => {
  try {
    const res = await axios.get("https://ipinfo.io?token=11534cdaa8e4de");
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const logOutApi = (obj) => async (dispatch) => {
  try {
    let location = await getCurrentLocation();
    let payload = {
      location: location,
      user_id: obj?._id,
      section: "Last logged out",
      status: "Success",
      service: "Sign Out",
      activity: "Log Out",
      email: obj?.email?.id,
      password: "",
      userType: obj?.isIssuer
        ? "Issuer"
        : obj?.isInvestor
        ? "Investor"
        : "User",
    };

    let url = `${apiURl.auditlog}`;
    const response = await API({
      base_url: BASECONFIG.BASE_URL,
      url: url,
      method: "POST",
      body: { ...payload },
      dispatch,
    });
    console.log(response);
    dispatch(LogoutUser({}));
    dispatch(SetPreKycData({}));
    dispatch(LogoutGoogleUser({}));
    dispatch(SetAuthUserData({}));
    dispatch(SetKycData({}));
    dispatch(SetAddNewProjectData({}));
    localStorage.clear();
  } catch (error) {}
};
