import { createSlice } from "@reduxjs/toolkit";

export const sliceIndustry = createSlice({
  name: "IndustryData",
  initialState: {
    data: [],
  },
  reducers: {
    fetched: (state, { payload }) => {
      state.data = payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
const { fetched } = sliceIndustry.actions;

export const SetIndustry = (data) => async (dispatch) => {
  dispatch(fetched({ data }));
};

export default sliceIndustry.reducer;
