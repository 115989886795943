/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetpopupReducerData } from "../../store/reducer";
import { MdOutlineWifiOff } from "react-icons/md";

function InternetConnectivityChecker() {
  const { popupReducer } = useSelector((state) => state);
  const {
    modalType = "",
    showModal = false,
    message = "",
  } = popupReducer?.modal;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(
      SetpopupReducerData({
        modalType: "",
        showModal: false,
      })
    );
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard="false"
        size="xs"
        centered
      >
        <Modal.Header closeButton style={{border:"0"}}>
          <Modal.Title>Attention! </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={" container"}>
            <div className={" text-center  twoFactor"}>
              {/* <img
                src={"../Image/wifi.png"}
                className={`my-2`}
                alt=""
                width={100}
                height={100}
              /> */}
              <MdOutlineWifiOff
                width={100}
                height={100}
                size={130}
                color="red"
              />
              <h6 className={` text-center text-danger`}>
                You are offline. Please check your internet connection.
              </h6>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{border:"0"}}>
          <button
            type="btn"
            className="mx-2 btn btn-sm btn-secondary"
            onClick={() => {
              handleClose();
              window.location.reload();
            }}
          >
            Refresh
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InternetConnectivityChecker;
