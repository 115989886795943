/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import {  SetpopupReducerData } from "../../store/reducer";
import { useNavigate } from "react-router";
import { logOutApi } from "../../config/GetCurrentLocation";

function IsSessionExist() {
  const { popupReducer, authUser } = useSelector((state) => state);
  const {
    modalType = "",
    showModal = false,
    message = "",
  } = popupReducer?.modal;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(
      SetpopupReducerData({
        modalType: "",
        showModal: false,
        message: "",
      })
    );
    dispatch(logOutApi(authUser?.data));
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleLogout();
    }, 300000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => handleLogout()}
        style={{ backdropFilter: "blur(5px)" }}
        size="md"
        centered
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header closeButton style={{border:"0"}}>
          <Modal.Title>Attention! </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={" container"}>
            <div className={" text-center  twoFactor"}>

              <img
                src={"./Image/expired.jpg"}
                className={`my-2`}
                alt=""
                width={100}
                height={100}
              /> 
              
              <h6 className={` text-center text-danger`}>
                {message
                  ? message
                  : "Session has expired. Please log in again."}
              </h6>
            </div> 
          </div>
        </Modal.Body>
        <Modal.Footer style={{border:"0"}}>
          <a href="mailto:support@almabrook.io" className="btn btn-primary rounded-pill btn-sm" style={{width:"20%"}}>Help</a>
          <button
            type="btn"
            className="btn btn-sm btn-primary rounded-pill"
            onClick={() => handleLogout()}
            backdrop="static"
            keyboard="false"
            style={{width:"20%"}}
          >
            Home
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default IsSessionExist;
