/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { validateRequire, isValid } from "../../../Validation/InputValidation";
import { SetloaderData } from "../../../store/reducer";
import { apiURl } from "../../../store/actions";
import { API } from "../../../apiwrapper";
import { toast } from "react-toastify";
import { BASECONFIG } from "../../../config";
import moment from "moment";

function AddCommentPopup({
  show,
  handleClose,
  callBackFunction = () => {},
  selectData = "",
  forApproval = false,
}) {
  const [errors, setErrors] = useState({});
  const { Loader, authUser } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  let [inpField, setInpField] = useState({
    comment: "",
  });
  let [previousComment, setPreviousComment] = useState([]);
  let [approvedInpField, setApprovedInpField] = useState({
    remarks: "",
    status: "",
  });
  let { comment } = inpField;
  let { remarks = "", status = "" } = approvedInpField;

  const handleChange = (e) => {
    setInpField({ ...inpField, [e.target.name]: e.target.value });
    handleValidate(e);
  };

  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "comment":
        errors1.comment = validateRequire(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };

  const validateAll = () => {
    let err1 = {};
    if (forApproval) {
      err1.status = validateRequire(status);
    } else {
      err1.comment = validateRequire(comment);
    }

    return err1;
  };
  const resetInputField = () => {
    setInpField({
      comment: "",
    });
    setApprovedInpField({
      remarks: "",
      status: "",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (forApproval && approvalCheckByUser?.length > 0) ||
      (!forApproval && previousComment?.length >= 1)
    ) {
      handleClose();
      return;
    }
    let submitButton = document.getElementById("submit");
    submitButton.disabled = true;
    dispatch(SetloaderData(true));
    try {
      let err = validateAll();
      if (isValid(err)) {
        let payload = forApproval ? { ...approvedInpField } : { ...inpField };
        let url = forApproval
          ? `${apiURl.updateUser}/work/approval/${selectData?._id}`
          : `${apiURl.updateUser}/work/comments/${selectData?._id}`;
        const data = await API({
          base_url: BASECONFIG.BASE_URL_USER,
          url: `${url}`,
          method: "PUT",
          dispatch,
          body: { ...payload },
        });

        if (data?.status || data?.status === true) {
          toast.success(data?.message);
          resetInputField();
          setTimeout(() => {
            callBackFunction();
          }, 500);
          handleClose();
        } else {
          toast.warn(data.message);
        }
      } else {
        setErrors(err);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      submitButton.disabled = false;
      dispatch(SetloaderData(false));
    }
  };
  const approvalCheckByUser = selectData?.approval?.filter((ele)=>ele?.user_id === authUser?.data?._id)

  useEffect(() => {
    if (selectData) {
      setInpField({ ...inpField });
      const commentArr = selectData?.comments?.filter(
        (ele) => ele?.user_id === authUser?.data?._id
      );
      setPreviousComment(commentArr);
      if (forApproval && selectData?.approval?.length > 0) {
        const obj = {
          remarks: selectData?.approval?.find((ele)=>ele?.user_id === authUser?.data?._id)?.remarks,
          status: selectData?.approval?.find((ele)=>ele?.user_id === authUser?.data?._id)?.status,
        };
        console.log(obj,'objobj')
        setApprovedInpField({ ...obj });
      }
    }
  }, [selectData]);
  console.log(previousComment, "commentArr");

  return (
    <>
      <Modal
        show={show}
        size="xs"
        onHide={handleClose}
        backdrop="static"
        centered
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{forApproval ? "Approval" : "Comments!"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {forApproval ? (
              <>
                {approvalCheckByUser?.length > 0 ? (
                  <h6 className="text-center my-5">
                    You Already {approvedInpField?.status} This
                    Approval.{" "}
                  </h6>
                ) : (
                  ""
                )}
                <div className="col-3 ms-1 ">
                  <div className="  form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Accepted"
                      name="status"
                      disabled={approvalCheckByUser?.length > 0 || false}
                      checked={approvedInpField?.status === "Accepted"}
                      id="Accepted"
                      onChange={(e) =>
                        setApprovedInpField({
                          ...approvedInpField,
                          status: e.target.value,
                        })
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="Accepted"
                      style={{ fontWeight: "400" }}
                    >
                      Accept
                    </label>
                  </div>
                </div>
                <div className="col-3 ms-1 ">
                  <div className=" form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Rejected"
                      name="status"
                      disabled={approvalCheckByUser?.length > 0 || false}
                      checked={approvedInpField?.status === "Rejected"}
                      id="Status2"
                      onChange={(e) =>
                        setApprovedInpField({
                          ...approvedInpField,
                          status: e.target.value,
                        })
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="Status2"
                      style={{ fontWeight: "400" }}
                    >
                      Reject
                    </label>
                  </div>
                </div>
                {errors.status ? (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    {errors.status}
                  </span>
                ) : (
                  ""
                )}

                <div className=" col-12 mt-2">
                  <div className="form-group ">
                    <label className="mb-2">Remarks</label>
                    <textarea
                      type="text"
                      value={remarks}
                      rows={4}
                      cols={4}
                      disabled={approvalCheckByUser?.length > 0 || false}
                      className="form-control"
                      placeholder="Enter remarks."
                      name="remarks"
                      onChange={(e) => {
                        setApprovedInpField({
                          ...approvedInpField,
                          remarks: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {previousComment?.length > 0 ? (
                  <div className="">
                    <ul className="ms-3">
                      {previousComment?.map((ele) => (
                        <li className=" mb-2">
                          {ele?.content}
                          {ele?.date ? (
                            <>
                              <br />
                              <small>
                                {moment(ele?.date)
                                  .local()
                                  .format("DD/MM/YYYY hh:mm a")}
                                {ele?.isRead ? (
                                  <img
                                    title="read"
                                    src="../Image/recepeintSucess.png"
                                    alt="read"
                                    className="ms-2"
                                    width={10}
                                    height={10}
                                  />
                                ) : (
                                  ""
                                )}
                              </small>
                            </>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                    <hr />
                    <p style={{ fontSize: "12px" }}>
                      contact via secure message for further discussion.
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {previousComment?.length >= 1 ? (
                  ""
                ) : (
                  <div className=" col-12">
                    <div className="form-group ">
                      <label className="mb-2">
                        Add Comment <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        value={comment}
                        className="form-control"
                        placeholder="Enter comment."
                        name="comment"
                        onChange={handleChange}
                        onBlur={handleValidate}
                      />
                      {errors.comment ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {errors.comment}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 text-end mt-2">
            <button
              className="btn btn-primary me-1 ml-2 mb-1 btn-sm"
              id="submit"
              onClick={handleSubmit}
              disabled={Loader?.data || false}
            >
              {Loader?.data ? (
                <Spinner size="sm" />
              ) : forApproval || previousComment?.length >= 1 ? (
                "Ok"
              ) : (
                "Add"
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddCommentPopup;
